<template>
	<div>
		<!-- 用户管理 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="addAccount('new')">新 增</el-button>
		</div>
		<template>
			  <el-table
			      :data="tableData"
			      style="width: 100%"
				  >
			      <el-table-column
			        label="序号"
			        width="180">
					<template slot-scope="scope">
						{{scope.$index+1}}
					</template>
			      </el-table-column>
			      <el-table-column
			        prop="status"
			        label="状态"
			        width="180">
					<template slot-scope="scope">
						<span :class="{cdanger:scope.row.status=== 2,csuccess:scope.row.status=== 1}">{{scope.row.status=== 1?'正常':scope.row.status=== 2?'异常':''}}</span>
					
					</template>
			      </el-table-column>
			      <el-table-column
			        prop="type"
			        label="类型"
			        width="180">
			      </el-table-column>
			      <el-table-column
			        prop="add_time"
			        label="添加时间"
			        width="180">
			      </el-table-column>
			      <el-table-column
			        prop="use_time"
			        label="使用时间">
			      </el-table-column>
			      <el-table-column
				    show-overflow-tooltip
			        prop="cookie"
			        label="cookie">
			      </el-table-column>
				  
				  <el-table-column label="操作" width="260px" align="center">
				  	<template slot-scope="scope">
						<div>
							<span class="mr10">
								<el-button type="primary" size="mini" @click="addAccount('update', scope.row)" >编辑</el-button>
							</span>
							<el-popover
							  placement="left"
							  width="160"
							  v-model="scope.row.delvisible">
							  <p>确定删除吗？</p>
							  <div style="text-align: right; margin: 0">
							    <el-button size="mini" type="text" @click="scope.row.delvisible = false">取消</el-button>
							    <el-button type="primary" size="mini" @click="delCookie(scope.row)">确定</el-button>
							  </div>
							  <el-button size="mini" type="danger" slot="reference">删除</el-button>
							</el-popover>
						</div>
				  		
				  	</template>
				  </el-table-column>
			    </el-table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" :title="title" :visible.sync="dialogVisible" @close="close":close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<div v-if="titleName" class="mb20">{{titleName}}</div>
					<el-form :label-position="labelPosition" label-width="100px">
						<el-form-item label="cookie">
							<el-input v-model="cookie" type="textarea" :rows="10" placeholder="请输入cookie"></el-input>
						</el-form-item>
						
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				//新增
				labelPosition: 'right',
				username:'',
				password:'',
				passwordSure: '', //确认密码
				type: '', //new  新增   roles配置角色
				title: '',
				titleName: '',
				submitText: '新增',
				roles:'',//角色
				rolesList:[],//角色列表
				rolePageSize:100,
				height:0,
				//cookie
				cookie: '',
			}
		},
		created() {
			this.cookieList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
				console.log(this.height)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			cookieList() {
				// this.loading = true
				this.$api.cookieList({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					this.loading = false
					if (res.status === 1) {
						this.tableData = res.data.data.map(item => ({
						      ...item,
						      delvisible: false  // 初始化每行的弹出框控制属性
						    }));
						this.total = res.data.total
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					console.log(res)
				}).catch(error => {
					this.loading = false
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.cookieList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.cookieList()
			},
			addAccount(type,item) {
				this.dialogVisible = true
				this.type = type
				if (item) {
					this.item = item;
				}
				if (this.type === 'new') {
					this.title = '新增cookie'
					this.submitText = '新增'
				}
				if (this.type === 'update') {
					this.cookie = item.cookie
					this.title = '编辑cookie'
					this.submitText = '编辑'
				}
			},
			close() {
				this.dialogVisible = false
				this.type="";
				this.cookie="";
				this.item = {};
			},
			//新增
			onSubmit() {
				if (this.type === 'new') {
					//新增商家
					if (isEmpty(this.cookie, '请输入cookie')) {
						return
					}
					let data = {
						cookie: this.cookie,
						type: 'pdd'
					}
					this.dialogloading = true
					this.$api.cookieAdd(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							this.cookieList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}
				else if(this.type == 'update'){
					this.cookieSet()
				}
			},
			//删除用户
			delCookie(item){
					this.loading = true;
					let data = {
						id: item.id,
					}
					this.$api.deleteCookie(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							item.delvisible = false;
							this.cookieList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				
			},
			//设置角色
			cookieSet(){
				let data = {
					id:this.item.id,
					cookie:this.cookie
				}
				this.dialogloading = true
				this.$api.cookieSet(data).then(res=>{
					this.dialogloading = false
					if(res.status == 1){
						this.close()
						this.cookieList()
						this.$message({type:"success",message:res.msg})
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			}
		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
					console.log(this.height)
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
</style>
